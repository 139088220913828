.mh__singleFilter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  select {
    border: 1px solid #e5e8ee;
    border-radius: 4px;
    padding: 12px;
    box-shadow: none;
    width: 30%;
  }
  input {
    border: 1px solid #e5e8ee;
    border-radius: 4px;
    padding: 10.5px 18px;
    &::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $gris3;
    }
  }
  & > div {
    &:hover {
      cursor: pointer;
    }
  }
  //   }
}
