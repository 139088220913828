.mh__containerProyects {
  display: flex;
  // align-items: center;
  background-color: $gris1;
  width: 35%;
  border-radius: 4px;
}

.mh__containerProyects-names {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70%;
  // padding-right: 21px;
  padding: 14px 18px;
  &:hover {
    cursor: pointer;
  }

  ul {
    margin: 0;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    text-transform: uppercase;
    line-height: 24px;
    color: $gris4;
    padding-inline-start: 0 !important;
    width: 100%;
    z-index: 1;
    & > li {
      & > p {
        margin-left: 5px !important;
      }
    }
    // padding: 5px;
    li {
      list-style: none;
      width: 100%;
      position: relative;
      &:hover {
        cursor: pointer;
      }
      p {
        margin: 0;
      }
      ul {
        position: absolute;
        background-color: #fff;
        display: none;
        width: 100% !important;
        // width: 100%;
        // box-shadow: ;
        li {
          padding: 5px;
        }
      }
    }
    span {
      color: $gris5 !important;
    }
  }
  &:hover {
    cursor: pointer;
  }
}
.mh__containerProyects-iconArrow {
  border-right: 1px solid $gris2;
  display: flex;
  align-items: center;
  padding-right: 15px;
  &:hover {
    cursor: pointer;
  }
  img {
    width: 100%;
  }
}
.mh__containerProyects-actions {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-left: 15px;
  padding-right: 8px;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    img {
      width: 100%;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .container-add {
    margin-left: 10px;
    padding: 5px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid $gris2;
  }
}
.mh__containerProyects-names-showItems {
  display: block !important;
  box-shadow: 0px 20px 20px rgba(182, 182, 182, 0.3);
  // padding: 5px;
}
.mh__containerProyects-names-containerOpen {
  background-color: #fff !important;
  box-shadow: 0px 4px 18px rgba(79, 82, 88, 0.3);
}
