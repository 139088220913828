.mh__search {
  width: 100%;
}

.mh__search-containerInput {
  margin-bottom: 32.5px;
  display: flex;
  align-items: center;
  position: relative;
}

.mh__search-searchIcon {
  // background-color: red;
  position: absolute;
  display: flex;
  align-items: center;
  // width: 6.5%;
  margin-left: 14px;

  img {
    width: 18px;
  }
}

.mh__serach-input {
  box-sizing: border-box;
  padding: 18px 12px 18px 45px;
  color: $negro;
  font-size: 16px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E5E8EE;
  box-shadow: 0px 6px 20px rgba(22, 40, 81, 0.12);
  border-radius: 4px;
}

.mh__search-resultHashtags-addedhashtag {
  background-color: $gris1;

  p {
    font-weight: 400;
  }
}

.mh__search-resultHashtags-btnSeeMore {
  width: 100%;
  padding: 6px 0;
  border-radius: 4px;
  margin-top: 18.5px;

  p {
    margin: 0;
    text-align: center;
    color: $principal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &:hover {
    transition: 0.5s;
    background-color: $principalFondo;
    cursor: pointer;
  }
}

.mh__search-error {
  background-color: rgb(252, 106, 106);
  padding: 5px;
  margin-top: -15px;
  border-radius: 4px;
  text-align: center;

  p {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 14px;
  }
}

.mh__search-empty {
  padding: 5px;
  margin-top: -15px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    p:first-of-type {
      color: $gris5;
      font-style: normal;
      font-weight: normal;
    }

    p {
      font-size: 15px;
      line-height: 24px;
      text-align: center;
      margin: 0;
      font-weight: 500;
    }
  }
}

.mh__search-iconNoHashtag {
  margin-bottom: 10px;
}