.mh__account {
  font-style: normal;
  font-weight: normal;
  color: #201f28;
  max-width: 65%;
  margin: auto;
  padding-bottom: 8rem;
}
.mh__account-title {
  font-style: normal;
  font-weight: normal;
  font-family: Poppins;
  font-size: 22px;
  line-height: 33px;
}

.mh__account-content {
  display: flex;
  justify-content: center;
}
.mh__facebook {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.mh__facebook-account {
  display: flex;
  align-items: center;
  background-color: $gris1;
  width: 80%;
  border-radius: 4px;
  padding: 12px 18px;
  div {
    margin-right: 16px;
    //   width: 30%;
    img {
      border-radius: 50%;
      height: 50px;
      object-fit: cover;
      object-position: center;
      width: 50px;
    }
  }
  p {
    color: $gris5;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }
}
.mh__account-info {
  width: 55%;
  //   background-color: #d3e0fd;
  label {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $gris5;
  }
  input:first-of-type {
    margin-bottom: 24px;
  }
  input {
    width: 92%;
    border: 1px solid #e5e8ee;
    border-radius: 4px;
    padding: 12px 18px;
    color: $gris5;
    margin-top: 10px;
    margin-bottom: 16px;
  }
  p {
    margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $principalOscuro;
    margin-bottom: 32px;
    &:hover {
      cursor: pointer;
    }
  }
}
.mh__account-info-btn {
  background-color: $principal;
  border-radius: 4px;
  color: #fff;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  width: 50%;
  text-align: center;
  padding: 10px 0;
  &:hover {
    cursor: pointer;
  }
}

.mh__facebook-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: $negro;
  margin: 0;
  margin-bottom: 32px;
}

.mh__facebook-button {
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: #3b5998;
  border-radius: 4px;
  color: white;
  border: none;
  cursor: pointer;
}
.mh__facebook-subtitle {
  width: 75%;
}
.mh__account-containerChange {
  margin-top: 16px;
  margin-bottom: 30px;
  background-color: $gris1;
  border-radius: 4px;
  padding: 22px 18px;
}
.mh__account-passError {
  background-color: rgb(247, 57, 57);
  border-radius: 4px;
  padding: 5px 0;
  p {
    margin: 0;
    text-align: center;
    color: #fff !important;
    font-size: 16px;
  }
}
.mh__account-spinner {
  display: flex;
  justify-content: center !important;
  align-items: center;
  width: 65%;
  margin-top: 1.5rem;
  // background-color: red;
}
.mh__account-error {
  margin-top: 1.5rem;
  p {
    margin: 0;
    color: red;
    text-align: center;
    font-size: 15px;
    font-weight: 300;
  }
}
.mh__account-btnDesvincular {
  background-color: #3b5998;
  border-radius: 4px;
  padding: 12px 0;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.3rem;
  // margin: 1.3rem auto;
  &:hover {
    cursor: pointer;
  }
  & > div {
    display: flex;
    align-items: center;
    margin-right: 6px;
    img {
      width: 100%;
    }
  }
  p {
    margin: 0;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
    color: #fff;
    text-transform: uppercase;
  }
}
.mh__facebook-card-title {
  color: $gris5;
  font-weight: 500;
  font-size: 15px;
}
.mh__facebook-cards {
  background-color: $gris1;
  border-radius: 4px;
  padding: 5px 10px;
  margin-bottom: 1rem;
  width: 80%;
  box-shadow: 0px 0px 5px #83838338;
  border: 1px solid #96969634;
  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 8px #35353541 !important;
  }
  p:first-of-type {
    font-size: 16px;
    color: $gris5;
    font-weight: 500;
    margin: 0;
  }
  p {
    font-size: 14px;
    color: $gris4;
    font-weight: 400;
    margin: 0;
    margin-top: 5px;
  }
}
