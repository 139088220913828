.mh__Infohastag {
  padding: 40px 48px;
}
.mh__infoHashtag-flex{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
  & > div{
    width: 46%;
  }
}

.mh__InfoHashtag-noInfo {
  width: 70%;
  margin: 144px auto 200px auto;
  text-align: center;

  img {
    margin-bottom: 30px;
  }

  p {
    margin: 0;
    color: $gris5;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
  }
}

.mh__infoHashtag-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 48px;

  button {
    background: #FFFFFF;
    border-radius: 4px;
    padding: 6px 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #716EFF;
    display: flex;
    align-items: center;
    border: none;
    img{
      margin-right: 9px;
    }
    &:hover{
      cursor: pointer;
    }
  }
}
.mh__infoHashtag-left{
  overflow: hidden;
}

@media all and (max-width:701px) {
  .mh__infoHashtag-flex{
    flex-direction: column;
    &>div{
      width: 100%;
    }
  }
  // .mh__infoHashtag-left{
  //   width: 100%;
  // }
}