.mh__hashtagsSelected-title {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    color: $gris5;
  }
}
.mh__hashtagsSelected-title-actions {
  display: flex;
  align-items: center;
  .btn-copy {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $principal;
    border-radius: 4px;
    padding: 6px 12px;
    &:hover {
      background-color: $principalOscuro;
      cursor: pointer;
      transition: 0.5s;
    }
    img {
      margin-right: 6px;
    }
    p {
      margin: 0;
      color: #fff;
      font-weight: 500;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
  .btn-delete {
    padding: 6px 12px;
    border-radius: 4px;
    margin-left: 12px;
    &:hover {
      background-color: $principalFondo;
      cursor: pointer;
      transition: 0.5s;
    }
    p {
      margin: 0;
      color: $principal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-transform: uppercase;
    }
  }
}
.mh__hashtagsSelected-containerhashtags {
  // width: 100%;
  background-color: $gris1;
  min-height: 90px;
  // height: 100%;
  padding: 18px;
  // overflow-y: scroll;
  margin-bottom: 80px;
  border-radius: 4px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
  & > div {
    display: flex;
    align-items: center;
    background-color: $gris2;
    margin-bottom: 10px;
    margin-right: 12px;
    border: 1px solid #dddcff;
    border-radius: 4px;
    padding: 2px 8px;
    p {
      color: $negro;
      font-size: 14px;
      line-height: 21px;
      font-weight: normal;
      font-style: normal;
      margin: 0;
      // width: 70%;
    }
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 15%;
    }
    img {
      // margin-left: 5px;
      width: 61%;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.mh__hashtagsSelected-copy {
  background-color: #e0ffdf !important;
  border: 1px solid #b4fbb3 !important;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px !important;
  position: absolute;
  right: 0;
  z-index: 1;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $negro !important;
  }
}
.mh__hashtagSelectd-copy-img {
  display: flex;
  align-items: center;
}

.mh__hashtagsSelected-error {
  background-color: #ffa86e;
  padding: 5px 10px;
  margin-bottom: 15px;
  border-radius: 4px;
  box-shadow: 0px 0px 20px rgba(79, 82, 88, 0.171);
  p {
    color: #fff;
    font-weight: 500;
    margin: 0;
  }
}

@media all and (min-width: 468px) {
  .mh__hashtagsSelected-title {
    flex-direction: row;
    margin-bottom: 12px;
  }
}

@media all and (min-width: 768px) {
}
