.mh__login {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mh__login-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  //   margin: auto;
  
}
.mh__login-container-left {
  background-color: $gris1;

  position: relative;
  & > div {
    margin-top: 58px;
    margin-left: 48px;
    margin-right: 50px;
  }
  h1 {
    color: #000;
    font-weight: normal;
    font-size: 32px;
    line-height: 48px;
  }
  .mh__login-subtitle{
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: $gris5;
    margin: 15px 0;
  }
}

.mh__login-svgBarra {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 !important;
}

.mh__login-container-right {
  // padding: 63px 48px;
  // background-color: red;
  position: relative;
  width: auto;
  
  .mh__login-title{
    color: $negro;
    font-weight: normal;
    font-style: normal;
    font-size: 22px;
    line-height: 33px;
    text-align: center;
  }
  

  & > div {
    // display: block;
    width: 80%;
    margin: auto;
    margin-bottom: 63px;
    padding-top: 35px;
    label {
      width: 100%;
      color: $gris5;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
    }
    input {
      padding: 15px 18px 15px 50px;
      width: 89.5%;
      margin-top: 10px;
      margin-bottom: 12px !important;
      border-radius: 4px;
      border: 1px solid #e5e8ee;
    }
    input:first-of-type {
      margin-bottom: 24px !important;
    }
  }
}
.mh__login-forgot {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  p {
    margin: 0;
    // text-align: end;
    color: $principal;
    font-size: 14px;
    font-weight: normal;
    line-height: 21px;
    margin-bottom: 32px;
    &:hover {
      cursor: pointer;
    }
  }
}
.mh__login-container-right-btn {
  background-color: $principal;
  border-radius: 4px;
  padding: 12px 0;
  p {
    color: #fff !important;
    text-align: center !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    margin: 0;
  }
  &:hover {
    cursor: pointer;
  }
}

.mh__login-error {
  background-color: rgb(245, 56, 56);
  border-radius: 4px;
  padding: 5px 1rem;
  margin-bottom: 10px;
  p {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 0.85rem;
  }
}
.mh__forgot-msgSuccess {
  background-color: rgb(18, 158, 0);
  border-radius: 4px;
  padding: 5px 1rem;
  margin-bottom: 10px;
  p {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 0.85rem;
  }
}
.mh__login-error {
  background-color: rgb(245, 56, 56);
  border-radius: 4px;
  padding: 5px 1rem;
  margin-bottom: 10px;
  // position: absolute;
  // top: 2%;
  // right: .5%;
  p {
    margin: 0;
    color: #fff;
    font-weight: 500;
    font-size: 0.85rem;
  }
}
.mh__login-container-btnForgot {
  margin-bottom: 130px;
}
.mh__register_container {
  margin-bottom: 48px !important;
}
.mh__forgot_containerSpinner {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.mh__login-container-input {
  position: relative;
  display: flex;
  align-items: center;
  & > div {
    position: absolute;
    // background-color: red;
    display: flex;
    align-items: center;
    left: 0.85rem;
    top: 1.5rem;
    img {
      width: 100%;
    }
  }
}
.mh__login-logo {
  width: 80%;
  margin:auto;
  img {
    width: 100%;
  }
}


@media all and(min-width: 468px){
  
}


@media all and (min-width: 768px) {
  .mh__login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .mh__login-container {
    max-width: 900px;
    flex-direction: row;
    justify-content: center;
    border-radius: 8px;
    box-shadow: 0px 4px 20px rgba(79, 82, 88, 0.2);
  }
  .mh__login-container-left {
    width: 46%;
  }

  .mh__login-container-right {
    width: 54%;
  }
}
