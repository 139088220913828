.mh__modalAccount-header {
  padding: 1.3rem;
  border-bottom: 1px solid $gris2;
  // background-color: red;
  // width: 100%;
  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: $negro;
  }
}
.mh__modalAccount-description {
  padding: 1.35rem 1.3rem;
  p {
    margin: 0;
    color: $gris5;
    font-size: 15px;
  }
}
.mh__modalAccount-btn {
  width: 50%;
  background-color: $principal;
  color: #fff;
  border-radius: 4px;
  margin: 2.5rem auto 0.5rem auto;
  text-align: center;
  padding: 5px;
  &:hover{
      cursor: pointer;
      background-color: $principalOscuro!important;
  }
}
