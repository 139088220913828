.noavailable {
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.noavailable-container {
    max-width: 1200px;
    width: 75%;
    background-color: #F7F7F9;
    border-radius: 40px;
    box-sizing: border-box;
    padding: 50px 90px;
    display: flex;
    justify-content: space-between;
}

.noavailable-container-title {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
    // background-color: red;

    h3 {
        font-size: 60px;
        line-height: 66px;

        span {
            color: #FF6C8B;
        }
    }
}

.noavailable-container-icons {
    width: max-content;

    &>div {
        width: 155px;
        height: 155px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;

        img {
            width: 60%;
        }
    }
}

.noavailable-icon1 {
    background-color: #A73FF8;
}
.noavailable-icon2 {
    background-color: #FF6C8B;
}
.noavailable-icon3 {
    background-color: #F8A077;
}
.noavailable-container-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 33%;
    h4{
        font-size: 20px;
        line-height: 30px;
        color: #201F28;
        margin-bottom: 30px;
    }
    p {
        font-size: 16px;
        line-height: 24px;
        color: #7C7F86;
    }
}