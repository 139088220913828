.announcement {
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #e1e1e1;
}

.announcement-container {
    width: 1059px;
    display: flex;
    border-radius: 20px;
    background-color: #fff;
}

.announcement-container-border {
    flex: 0 0 21px;
    & > div {
        width: 100%;
        height: calc(100% / 11);
    }
}
.announcement-border-1{
    background-color: #E4A078;
    border-top-left-radius: 20px;
}
.announcement-border-2{
    background-color: #E48978;
}
.announcement-border-3{
    background-color: #E26C8A;
}
.announcement-border-4{
    background-color: #C836BF;
}
.announcement-border-5{
    background-color: #B339D3;
}
.announcement-border-6{
    background-color: #913FF4;
}
.announcement-border-7{
    background-color: #913FF4;
}
.announcement-border-8{
    background-color: #716EFF;
}
.announcement-border-9{
    background-color: #6C7CFF;
}
.announcement-border-10{
    background-color: #6890FF;
}
.announcement-border-11{
    background-color: #68A0FF;
    border-bottom-left-radius: 20px;
}


.announcement-container-title {
    flex: 0 0 45%;
    box-sizing: border-box;
    padding: 50px 30px;

    img {
        width: 65%;
        margin-bottom: 10px;
    }

    p {
        font-size: 32px;
        line-height: 41.6px;
        color: #201F28;
    }

}

.announcement-container-description {
    box-sizing: border-box;
    padding: 40px 30px;
    
    p {
        font-size: 16px;
        line-height: 24px;
        color: #7C7F86;
        width: 95%;
    }

}