.mh__overview {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &>div {
        // width: 42%!important;
        width: 100% !important;
        background-color: #fff;
        padding-top: 18px;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 30px;

        h3 {
            // text-align: left;
            color: $gris5;
            font-size: 16px;
            font-weight: 500;
            line-height: 27px;
            margin: 0;
            margin-bottom: 17px;
        }
    }
}

.mh__overview-chart {
    &>div {
        display: flex;

        &>div {
            width: 49%;
        }

        div:first-of-type {
            margin-right: 15px;
        }
    }
}

.mh__overview-chart-leyenda {
    display: flex;

    // align-items: center;
    .is-purple {
        background-color: $rosa;
        height: 6px;
        width: 18px;
        margin-top: 6%;
        border-radius: 3px;
        margin-right: 10px;
    }

    .is-blue {
        background-color: $principal;
        height: 6px;
        width: 18px;
        margin-top: 6%;
        border-radius: 3px;
        margin-right: 10px;
    }

    .is-blue-light {
        background-color: #DDDCFF;
        height: 6px;
        width: 18px;
        margin-top: 6%;
        border-radius: 3px;
        margin-right: 10px;
    }
}

.mh__overview-chart-leyenda-description {
    p:first-of-type {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        font-style: normal;
        margin: 0 !important;
        color: $negro;
    }

    p {
        margin: 0 !important;
        color: $gris4;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
    }
}

.mh__nube {
    background-color: #fff;
    padding: 20px;
    box-sizing: border-box;
}


.mh__chart-leyends {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

@media all and (max-width:985px) {
    .mh__overview-chart {
        &>div {
            flex-direction: column;
            align-items: center;
        }

    }

    .mh__chart-leyends {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        width: 100% !important;
        justify-content: flex-start;
        // padding: 20px 20px 0 20px;
        // justify-content: center;
    }

    .mh__overview-chart-leyenda{
        // align-items: center;
        width: 45%;
        margin-bottom: 8px;
    }
}
@media all and (max-width:701px) {
    .mh__nube{
        margin-top: 10px;
    }
}

@media all and (min-width:468px) {
    .mh__chart {
        display: flex;
        justify-content: center;

        canvas {
            width: 200px !important;
            height: 200px !important;
        }
    }
}