.mh__publishContainer {
  width: 100%;
  margin: 20px auto;
}
.mh__publishContainer-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 25px;
  div:first-of-type {
    display: flex;
    align-items: center;
    font-style: normal;
    p {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $gris4;
      margin: 0;
      margin-left: 16px;

      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
}
.mh__publishContainer-textActive {
  color: $principalOscuro !important;
  font-weight: 500 !important;
}
.mh__publishContainer-rowFiltro {
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
  & > div {
    margin-right: 9.5px;
    img {
      width: 100%;
    }
  }
  p {
    margin: 0;
  }
}
.mh__publishContainer-cards {
  // display: flex;
  // flex-wrap: wrap;
  // align-items: center;
  // justify-content: space-between;
  column-count: 1;
  column-gap: 0;
  & > div {
    background-color: #fff;
    // width: 47%;
    border-radius: 0px 0px 4px 4px;
    // margin-bottom: 20px;

    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    margin-bottom: 10px;
    break-inside: avoid;
  }
}
.mh_publishContainer-cardsInfo {
  display: flex;
  width: 88.5%;
  flex-direction: column;
    //   background-color: red;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
  & > div {
    width: auto;
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $negro;
      margin: 0;
    }
  }
}


.mh_publishContainer-cardsLike{
  order: 2;   
}

.mh__publishContainer-cardImg {
  width: 100%;
  & > div {
    width: 100%;
    height: 18rem;
    position: relative;
  }

  img,
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    &:hover {
      cursor: pointer;
    }
  }
}

.mh__publishContainerDescription {
  padding: 17px;
  p:first-of-type {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $negro;
  }
  p {
    font-weight: normal !important;
    font-size: 13px !important;
  }
}
.mh__publishContainerDescription-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  p {
    margin: 0;
    margin-right: 6px !important;
    color: $negro;
    &:hover {
      cursor: pointer;
      font-size: 13.5px !important;
      font-weight: 500 !important;
      transition: 0.5s;
    }
  }
}

.mh__publishContainer-hide {
  display: none !important;
  position: absolute;
  background-color: #fff;
  padding: 5px 10px;
  margin-left: 8%;
  box-shadow: 0px 4px 20px rgba(79, 82, 88, 0.2);
  // width: 10%;
  // left: 5%;
  span {
    &:hover {
      cursor: pointer;
    }
  }
}
.mh__publishContainer-show {
  display: block !important;
  z-index: 1;
}
.mh__publish-mediaPost {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  position: absolute;
  font-size: 1.3rem;
  background: rgb(255, 255, 255);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 3px 0 rgba(141, 141, 141, 0.178);
  top: 0;
  right: 0px;
}

.mh__publishContainer-filter {
  background-color: #fff;
  padding: 32px;
  margin-bottom: 24px;
  border-radius: 4px;
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: $gris4;
    margin: 0;
    margin-bottom: 12px;
  }
 
}
.mh__publishContainer-btnAddFilter {
  margin: 25px auto;
  // background-color: red;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &:hover{
    cursor: pointer;
  }
  & > div {
    margin-right: 4px;
  }
  p {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $principal;
    text-transform: uppercase;
  }
}


@media all and(min-width: 468px){
  .mh__publishContainer-cards {
    column-count: 2;
    column-gap: 20px;
  }
}



@media all and(min-width: 768px){
  .mh__publishContainer-cards {
    column-gap: 30px;
  }
}


@media all and(min-width: 1200px){
  .mh_publishContainer-cardsComments{
    order: 2;   
  }
  .mh_publishContainer-cardsInfo {
    flex-direction: row;
  }
}