.mh__containerProfile {
  width: 7%;
  padding: 10px 5px;
  background-color: $gris1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
  }
}
.mh__containerProfile-img {
  width: 55%;
  // background-color: rebeccapurple;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    border-radius: 50%;
    height: 30px;
    object-fit: cover;
    object-position: center;
    width: 30px;
  }
}
.mh__containerProfile-icon {
  width: 35%;
  position: relative;
  // background-color: red;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.mh__containerProfile-icon-settings {
  background-color: #fff;
  position: absolute;
  //   display: flex;
  flex-direction: column;
  justify-content: center!important;
  align-items: flex-start!important;
  box-shadow: 0px 4px 20px rgba(79, 82, 88, 0.2);
  border-radius: 4px;
  right: -0.5rem;
  top: 2rem;
  padding-top: 4px;
  padding-bottom: 4px;
  display: none !important;
  width: 12rem;

  & > div {
    display: flex;
    // background-color: red;
    // justify-content: flex-start;
    align-items: center;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    padding: 8px 20px;
    text-transform: uppercase;
    text-align: left;
    justify-content: flex-start;
    width: 80%;
    &:hover {
      background-color: $principalFondo;
      cursor: pointer;
    }
    & > div {
      display: flex;
      align-items: center;
      margin-right: 10px;
    }
  }
}
.mh__containerProfile-showSettings {
  display: flex !important;
}
