.mh__header {
  width: 100%;
  padding: 30px 0;
  border-bottom: 1px solid $gris2;
  margin-bottom: 40px;
}
.mh__header-container {
  max-width: 1200px;
  width: 85%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // h1 {
  //   font-style: normal;
  //   font-weight: normal;
  //   font-size: 30px;
  //   line-height: 48px;
  //   color: #000;
  //   &:hover{
  //     cursor: pointer;
  //   }
  // }
}


.mh__header-logo{
  width: 19%;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover{
    cursor: pointer;
  }
  img{
    width: 100%;
  }
}

@media screen and (max-width: 920px) {
  .mh__containerProyects{
    width: 50%!important;
  }
  
}
@media screen and (max-width: 800px) {
  .mh__containerProyects{
    width: 60%!important;
  }
  
}
@media screen and (max-width: 750px) {
  .mh__header-container{
    flex-wrap: wrap;
  }
  .mh__header-logo{
    width: 100%;
    margin-bottom: 30px;
    img{
      width: 40%;
    }
  }
  .mh__containerProfile{
    width: 12%!important;
  }
  .mh__containerProyects{
    width: 82%!important;
    box-sizing: border-box;
  }
  
}
@media screen and (max-width: 480px) {
  .mh__containerProyects{
    width: 80%!important;
  }
}