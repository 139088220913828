.mh__modalCreateProyect-create {
  // background-color: red;
  padding: 20px;
  display: flex;
  flex-direction: column;
  .container-imgHash {
    display: flex;
    justify-content: center;
    img {
      width: 12%;
    }
  }
  .container-imgClose {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    img {
      width: 6%;
      &:hover {
        cursor: pointer;
      }
    }
  }
  h3 {
    color: $negro;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin-bottom: 40px;
  }
  label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $gris5;
    padding-left: 3px;
  }
  .mh_modalEdit-delete {
    margin-top: 8px;
    margin-bottom: 48px;
    text-align: end;
    // margin: 0;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $gris4;
    &:hover {
      cursor: pointer;
    }
  }
  .mh_modalEdit-msgdelete {
    margin: 0 auto 48px auto;
    width: 85%;
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: $gris4;
  }
  input {
    padding: 12px 18px;
    border: 1px solid #e5e8ee;
    border-radius: 4px;
    margin-top: 14px;
    // margin-bottom: 48px;
    font-family: "Poppins";
    font-size: 16px;
    &::placeholder {
      font-weight: 400;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      color: $gris4;
    }
  }
  .mh__modalCreateProyect-create-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
      padding: 12px 24px;
      border-radius: 4px;
      &:hover {
        cursor: pointer;
      }
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin: 0;
      }
    }
    div:first-of-type {
      background-color: #fff;
      color: $principal;
    }
    div {
      background-color: $principal;
      color: #fff;
    }
  }
}
.mh__modalCreateProyect-create-error {
  background-color: rgb(252, 106, 106);
  padding: 5px;
  margin-top: -15px;
  border-radius: 4px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 20px;
  p {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 14px;
  }
}
.mh__modalEdit-btnDelete {
  background-color: #d93f3f !important;
}
.mh__modalCreateProyect-margin {
  margin-top: 48px !important;
}
