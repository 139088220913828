.mh__cardhashtag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  border: 1px solid $gris2;
  border-radius: 4px;
  margin-bottom: 8px;
  width: 100%;
  p {
    color: $gris5;
    margin-left: 18px;
    margin-top: 11px;
    margin-bottom: 11px;
    font-weight: normal;
    text-overflow: ellipsis;
  }
}
.mh__cardhashtag-name {
  width: 65%;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
}
.mh__cardhashtag-container {
  display: flex;
  align-items: center;
  width: 32%;
}
.mh__cardhashtag-container-post {
  // width: 100%;
  width: 85%;
  margin-right: 5px;
  overflow: hidden;
  p {
    font-size: 13px !important;
    color: $gris3;
    margin: 0 !important;
    padding: 0;
    font-weight: 500;
  }
}
.mh__cardhashtag-actionIcon {
  // background-color: rebeccapurple;
  color: #b9bec7;
  padding: 10px;
  width: 15px;
  border-left: 1px solid #e5e8ee;
  // margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 1.45em;
    &:hover {
      cursor: pointer;
    }
  }
}
.mh__cardhashtagSelected {
  background-color: $principalFondo !important;
  border-left: 4.07px solid $principal !important;
  p {
    color: $principalOscuro !important;
  }
  .mh__cardhashtag-containerSelected {
    p {
      color: $gris4 !important;
      font-weight: 500;
    }
    .mh__cardhashtag-actionIcon {
      border-left: 1px solid #dddcff !important;
    }
  }
}
.mh__cardHashtag-error {
  background-color: rgb(252, 106, 106);
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
  text-align: center;
  p {
    color: #fff;
    font-weight: 400;
    margin: 0;
    font-size: 14px;
  }
}
