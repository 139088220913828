.mh__tabbar {
  display: flex;
  align-items: center;
  padding-left: 48px;
  border-bottom: 2px solid $gris2;
  //   width: 93.5%;
  & > div {
    margin-right: 63px;
    margin-bottom: -2px;
    &:hover {
      .mh__tabbar-underline {
        background-color: $principal !important;
      }
      p {
        color: $principalOscuro !important;
      }
      cursor: pointer;
      //   border-bottom-color: $principal !important;
    }
    .mh__tabbar-underline {
      border-radius: 4px 4px 0px 0px;
      background-color: transparent;
      height: 5px;
      // width: 20px;
    }
  }
  p {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: $gris4;
    text-transform: uppercase;
    padding-bottom: 5px;
    margin-bottom: 0;
    display: block;
    z-index: 1;
    position: relative;
    // border-bottom: 5px solid transparent;
  }
}

.mh__tabbar-underline-active {
  background-color: $principal !important;
}
.mh-tabbar-active {
  p {
    color: $principalOscuro !important;
  }
}
