@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

body{
    font-family: 'Poppins', sans-serif!important;
    margin: 0;
    box-sizing: border-box;
}
input{
    font-family: 'Poppins', sans-serif!important;
    &:focus{
        outline: none;
    }
}
// Paleta de colores
$gris1 : #F7F7F9;
$gris2 : #E5E8EE;
$gris3 : #B9BEC7;
$gris4: #7C7F86;
$gris5: #4F5258;
$principal: #716EFF;
$principalFondo: #EBEBFF;
$principalOscuro: #5350EC;
$principalClaro: #DDDCFF ;
$rosa: #C836BF;
$negro: #201F28;

.ReactModal__Content{
    padding: 0!important;
}

.mh__container{
    max-width: 1200px;
    width: 85%;
    margin: 0 auto;
}

.mh__content{
    position: relative;
}

.mh__search-content{
    position: absolute;
    width: 60%;
    top: -40px;
    left: 20%;
}

.mh__content-hashtag{
    // flex: 0.70;
    
    background-color: $gris1;
    padding-top: 70px; 
    min-height: 450px ;
    height: fit-content;
    margin-top: 15px;
    h3{
        font-weight: normal;
        font-style: normal;
        font-size: 22px;
        line-height: 33px;
        // margin-left: 48px;
        // margin-bottom: 24px;
        color: $negro;
    }
}
.ReactModal__Content{
    position: relative;
    z-index: 2!important;
}


@media all and (min-width:468px){

}


@import './_form-search.scss';
@import './_info-hashtag-selected';
@import './_data-hashtag-selected.scss';
@import './_tabbar-section';
@import './_card-hashtag';
@import './_overview';
@import './_header';
@import './container-proyects';
@import './container-profile';
@import './hashtagsSelected';
@import './login-hashtag';
@import './modal-create-proyects';
@import './spinner';
@import './publish-container';
@import './spinner_progress-indicator';
@import './single-filtro';
@import './modal-account';


// Account 
@import './account/account';


// Banner styles
.banner{
    background-color: #DDDCFF;
    padding: 30px 0;
}
.banner-container{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    position: relative;
}
.banner-close{
    position: absolute;
    right: 0;
    top: 0;
}
.banner-close:hover{
    cursor: pointer;
}

.banner-container-logo{
    width: 61px;
    height: 61px;
    background-color: #716EFF;
    border-radius: 15.74px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 40px;
    img {
        // width: 100%;
    }
}
.banner-container-info{
    p{
        margin-top: 0;
        margin-bottom: 12px;
        font-size: 20px;
        line-height: 30px;
        &:last-of-type{
            margin: 0;
            font-size: 16px;
            line-height: 24px;
        }
    }
}

@import "./announcement";
@import "./noAvailable"