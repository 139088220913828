.mh__dataHashtag {
  // margin: 0 auto;
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  // width: 100%;
  & > div {
    // background-color: red;
    // width: 18%;
    // text-align: start;
    p {
      &:first-of-type {
        color: $gris4;
        font-weight: 500;
        font-style: normal;
        font-size: 14px;
        line-height: 21px;
        margin: 0;
        margin-bottom: 3px;
        text-transform: capitalize;
      }
      color: $gris4;
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      font-style: normal;
      margin: 0;
    }
  }
}

.mh__dataitem{
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content:center;
}

.mh__dataHashtag-row {
  display: flex;
  align-items: center;
  padding-top: 8px;
  margin: 0px;
  p {
    color: $negro !important;
    font-weight: 500 !important;
    font-style: normal !important;
    font-size: 24px !important;
    line-height: 36px !important;
    margin: 0 !important;
    text-transform: uppercase;
  }
  img {
    width: 18px;
    margin-right: 6px;
  }
}


@media all and(min-width: 768px){
  .mh__dataHashtag {
    justify-content: space-around;
  }
  .mh__dataitem{
    margin: 10px 0;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content:center;
    flex-wrap: wrap;
  }
}
// @media all and (max-width:701px) {
//     .mh__dataHashtag{
//       width: 100%;
//     }
// }